@import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;700;800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
body {
  overflow-x: hidden;
}
@layer base {
  .font-outline-2 {
    -webkit-text-stroke: 2px white;
  }
}
main {
  overflow: hidden;
}
.App {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Jost;
  font-style: normal;
  line-height: normal;
  font-weight: 700;
}
h1 {
  font-size: 60px;
}
h2 {
  font-size: 36px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 20px;
}
h5{
  font-size: 16px;
}
h6{
  font-size: 14px;
}
p {
  font-family: Jost;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.show_nav {
  position: fixed;
  top: -150px;
  width: 100%;
  transition: top 0.3s;
  z-index: 999;
  background: #fff;
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
    0px 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.main_container1 {
  @apply max-w-[1360px] mx-auto px-5 xl:px-7;
}
.main_container2 {
  @apply 4xl:w-[95%] w-full  px-5 4xl:pl-24;
}
.btn1 {
  @apply py-3 px-4 rounded 
    inline-flex font-Jost  items-center font-normal  gap-3 capitalize transition-all ease-in-out duration-500 border ;
}
.btn1:hover {
  @apply transition-all ease-in-out duration-500;
}

.nav_item {
  @apply font-normal 2xl:mr-7 mr-4  relative flex text-center capitalize;
}

.nav_link {
  @apply flex items-center font-Jost  h-full font-normal text-sm 2xl:text-base;
}
.nav_link.active {
  @apply text-troo-primary relative before:absolute before:content-[''] before:w-5 before:h-[2px] before:bg-troo-primary before:bottom-0 before:left-2; 
}
.draopDownActive {
  @apply text-troo-white bg-troo-secondary font-semibold;
}


@media (max-width:1024px){
  h1 {
    font-size: 50px;
  }
  h2 {
    font-size: 32px;
    line-height: 40px;
  }

}
@media (max-width:768px){
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 24px;
    line-height: 40px;
  }
  h3 {
    font-size: 18px;
  }
  h4 {
    font-size: 16px;   
  }
  h5 {
    font-size: 14px;
  }
  h6 {
    font-size: 12px;
  }
  p {
    font-size: 14px;
  }
}